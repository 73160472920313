@import '../variables';

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: ($spacer * 1),
    5: ($spacer * 1.25),
    6: ($spacer * 1.5),
    7: ($spacer * 1.75),
    8: ($spacer * 2),
    9: ($spacer * 2.25),
    10: ($spacer * 2.5),
    11: ($spacer * 2.75),
    12: ($spacer * 3),
    13: ($spacer * 3.25),
    14: ($spacer * 3.5),
    15: ($spacer * 3.75),
    16: ($spacer * 4),
  ),
  $spacers
);

$primary: map-get($colors, 'purple');
$secondary: map-get($accents, 'accentSecondary');
$tertiary: map-get($accents, 'accentTertiary');
