@import '../../../shared/styles/variables';

.separator {
  &.vertical {
    border-right: 1px solid map-get($colors, 'grayDarker');
  }

  &.horizontal {
    width: 100%;
    border-top: 1px solid map-get($colors, 'grayDarker');
  }
}
