@import '../../../shared/styles/variables';
@import '../../../shared/styles/mixins';

.palletPreview {
  background-color: map-get($colors, 'black');
  width: 100%;
  height: 146px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  cursor: move;

  .palletDescription {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: pxToRem(13px);
  }

  .detailsBtn {
    padding: 2px 6px;
    border-radius: 4px;
    border: none;

    &:focus {
      background: transparent;
    }
  }
}
