.modalRemoveDependency {
  filter: drop-shadow(0px 0px 44px #2F2F31);

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
