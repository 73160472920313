@import "../../../shared/styles/variables";
@import "../../../shared/styles/mixins";

.detailsModal {
  .closeBtn {
    @include removeButtonDefaultStyles;
    width: 18px;
    height: 18px;
    padding: 4px;
    display: flex;

    &:hover, &:focus {
      background-color: map-get($colors, 'grayHighlight');
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    background-color: map-get($colors, 'grayDarkest');
    padding: 30px 30px 0;
  }

  .body {
    background-color: map-get($colors, 'grayDarkest');
    padding: 4px 30px 30px 30px;
  }

  .tabPanel {
    padding-top: 40px;

    .descriptionTabText {
      height: 240px;
      overflow-y: scroll;
      margin-bottom: 20px;
    }
  }
}
