@import '../../../../shared/styles/variables';
@import '../../../../shared/styles/mixins';

.modalGenerateCode {
  filter: drop-shadow(0px 0px 44px #2F2F31);

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .deployBtn {
    @include removeButtonDefaultStyles;
    background-color: map-get($colors, 'grayDarkest');
    padding: 25px;
    width: 180px;
    height: 142px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;

    &:hover {
      background-color:map-get($colors, 'purple') ;
    }
  }
}
