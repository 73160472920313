@import '../../../shared/styles/variables';
@import '../../../shared/styles/mixins';

.sidebarIcon {
  @include removeButtonDefaultStyles;
  width: 100%;
  height: 115px;
  margin-bottom: 36px;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;

  &:hover, &:focus, &.selected {
    background-color: map-get($colors, 'grayHighlight');
    transition: 0.2s;
    outline: none;
  }

  &.selected {
    .activeIndicator {
      opacity: 1;
    }
  }

  .activeIndicator {
    align-self: start;
    height: 100%;
    width: 6px;
    background-color: map-get($colors, 'pink');
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    .icon {
      width: 25px;
      height: 25px;
      margin-bottom: 16px;
    }

    .title {
      color: white;
      font-family: Poppins;
      font-weight: bold;
      font-size: 12px;
    }
  }
}
