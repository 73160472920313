@import '../../../shared/styles/variables';

.dependencyList {
  .dependencyBox {
    background-color: map-get($colors, 'grayDarker');
    padding: 4px 8px;
    color: map-get($colors, 'white');
    font-family: 'Roboto Mono';
    margin-bottom: 8px;
    font-size: 14px;
  }
}
