.appButton {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;

  &.flat {
    border: none;
    background: none;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
