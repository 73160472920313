@import '../../../shared/styles/variables';

.requestLinkBox {
  text-transform: unset;
  border: 2px solid;
  border-image: map-get($accents, 'accentPrimary');
  border-image-slice: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-direction: column;
  text-align: center;
}
